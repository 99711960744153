var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReadyToDisplay)?_c('div',{class:[
    'ep-message-dashboard py-4 d-flex flex-column row-gap-6 border-1 border-neutral-n-4-line rounded-lg',
    _vm.classList,
    {
      'desktop': _vm.isDesktop,
      'tablet': _vm.isTablet,
      'mobile': _vm.isMobile,
    }
  ]},[_c('h3',{staticClass:"text-left m-0 px-4"},[_vm._v(" "+_vm._s(_vm.$t('ep-dashboard.message.title'))+" ")]),_c('div',{staticClass:"message-list hide-scrollbar w-100 h-100 px-2"},[(_vm.basicFilter && _vm.chatStoreCreated)?_c('message-box-list-view',{key:"message-box-list-view",attrs:{"disable-group-creation":true,"is-basic-layout":true,"override-basic-filter":_vm.basicFilter,"store-name":_vm.storeContext},on:{"open-messages":function (group) {
        _vm.$router.push({
          name: 'exhibitor-portal-message',
          query: { group: group.uid }
        })
      }}}):_vm._e()],1),_c('div',{staticClass:"px-4"},[_c('button-component',{attrs:{"text":_vm.$t('ep-dashboard.message.go-to-btn'),"size":"md","variant":"secondary"},on:{"on-click":_vm.goToMessages}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }