import { render, staticRenderFns } from "./EpMessageDashboardWidget.vue?vue&type=template&id=356e3d95&scoped=true&"
import script from "./EpMessageDashboardWidget.vue?vue&type=script&lang=ts&"
export * from "./EpMessageDashboardWidget.vue?vue&type=script&lang=ts&"
import style0 from "./EpMessageDashboardWidget.vue?vue&type=style&index=0&id=356e3d95&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "356e3d95",
  null
  
)

export default component.exports